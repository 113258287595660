<template>
  <div class="reserve" ref="reserve">
    <h2>查询条件</h2>
    <div class="box">
      <div class="select-box">
        客户简称
        <a-select
          v-model:value="search.customerId"
          @change="selectClient"
          class="mt5"
          style="width: 200px"
        >
          <a-select-option value="">--所有--</a-select-option>
          <a-select-option v-for="item in customerData" :value="item.id" :key="item.id"
            >{{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="select-box">
        套餐名称
        <a-select v-model:value="search.productId" class="mt5" style="width: 200px">
          <a-select-option v-if="comboName.length > 0" value="">--所有--</a-select-option>
          <a-select-option v-else value="">--没有套餐数据--</a-select-option>
          <a-select-option v-for="item in comboName" :value="item.id" :key="item.id"
            >{{ item.fyuName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="select-box">
        订单状态
        <a-select v-model:value="search.status" class="mt5" style="width: 200px">
          <a-select-option v-for="item in status" :value="item.key" :key="item.key"
            >{{ item.value }}
          </a-select-option>
        </a-select>
      </div>
      <div class="select-box">
        姓名
        <a-input v-model:value="search.userName" class="mt5" placeholder="员工姓名" />
      </div>
    </div>
    <div class="button-box">
      <a-button :loading="getLoading" style="width: 85px" type="primary" @click="getListData"
        >查询
      </a-button>
      <a-button style="width: 85px" type="primary" @click="reset">重置</a-button>
    </div>
  </div>
  <a-table
    bordered
    :columns="columns"
    :dataSource="dataSource"
    class="mt30"
    :pagination="position"
    :loading="loading"
    @change="tableChange"
    :customRow="getRowDetail"
  >
  </a-table>
  <a-modal
    :width="750"
    v-model:visible="visible"
    :getContainer="$refs.reserve"
    centered
    :maskClosable="false"
    title="体检预约详情"
    :footer="null"
  >
    <a-divider orientation="left">套餐信息</a-divider>
    <a-descriptions :column="2">
      <a-descriptions-item label="客户简称">{{ rowDetail.customerName }}</a-descriptions-item>
      <a-descriptions-item label="合同编号">{{ rowDetail.contractCode }}</a-descriptions-item>
      <a-descriptions-item label="套餐名称">{{ rowDetail.productName }}</a-descriptions-item>
      <a-descriptions-item label="泛员价">{{ rowDetail.fyuPrice }}</a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left">员工信息</a-divider>
    <a-descriptions :column="2">
      <a-descriptions-item label="员工姓名">{{ rowDetail.peopleName }}</a-descriptions-item>
      <a-descriptions-item label="证件号码">{{ rowDetail.peopleId }}</a-descriptions-item>
      <a-descriptions-item label="城市">{{ rowDetail.defaultCity }}</a-descriptions-item>
      <a-descriptions-item label="性别">{{ rowDetail.gender }}</a-descriptions-item>
      <a-descriptions-item label="婚否">{{ rowDetail.isMarried }}</a-descriptions-item>
      <a-descriptions-item label="联系方式">{{ rowDetail.contactInfo }}</a-descriptions-item>
    </a-descriptions>
    <a-divider orientation="left">预约信息</a-divider>
    <div style="height: 200px; overflow: auto">
      <template v-for="(item, index) in rowDetail.reservations" :key="index">
        <h1 style="margin-bottom: 15px">
          体检时间:{{ reserveTime(item.reserveTime) }} 体检门店:{{ item.shopName }}
        </h1>
        <a-timeline>
          <a-timeline-item v-for="(content, cIndex) in item.recordList" :key="cIndex">
            {{ content.status }} {{ content.time }}
          </a-timeline-item>
        </a-timeline>
      </template>
    </div>
  </a-modal>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { getAnnualData, getCustomerData, getOrderDetail, getOrderStatus, getProducts } from '@/api';
import { Modal } from 'ant-design-vue';

//客户列表数据
const customerData = ref({});

const getCustomer = () => {
  getCustomerData()
    .then(res => {
      customerData.value = res.data;
    })
    .catch(err => {
      Modal.error({
        title: '客户列表请求出错,请刷新重试',
        content: err.response.data
      });
    });
};
getCustomer();
//表格数据loading
const loading = ref(false);
//列表数据
const dataSource = ref([]);
//列表配置
const columns = [
  {
    title: '客户简称',
    dataIndex: 'customerName',
    key: 'customerName',
    align: 'center'
  },
  {
    title: '合同编号',
    dataIndex: 'contractCode',
    key: 'contractCode',
    align: 'center'
  },
  {
    //不限,男,女
    title: '套餐名称',
    dataIndex: 'peopleName',
    key: 'productName',
    align: 'center'
  },
  {
    // 0已结婚 1未结婚 2不限 传空为全部
    title: '员工姓名',
    key: 'peopleName',
    dataIndex: 'peopleName',
    align: 'center'
  },
  {
    title: '证件号',
    key: 'peopleId',
    dataIndex: 'peopleId',
    align: 'center'
  },
  {
    title: '订单状态',
    key: 'status',
    dataIndex: 'status',
    align: 'center'
  },
  {
    //true有效
    title: '预约次数',
    key: 'reserveCount',
    dataIndex: 'reserveCount',
    align: 'center'
  }
];
//分页配置
const position = reactive({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  pageSizeOptions: ['10', '20', '30', '100'],
  total: 0,
  showTotal: total => `共${total}条`,
  position: ['bottomLeft']
});
//搜索初始化数据
const formSource = () => {
  return reactive({
    pageIndex: 1,
    customerId: '',
    productId: '',
    status: '',
    userName: ''
  });
};
//搜索条件
let search = formSource();
let conditionData = formSource();
//重置
const reset = () => {
  search = Object.assign(search, formSource());
};

const getInquire = pagination => {
  loading.value = true;
  getLoading.value = true;
  if (pagination) {
    conditionData.pageIndex = pagination.current;
    conditionData.pageSize = pagination.pageSize;
  } else {
    search.pageIndex = 1;
  }
  getAnnualData(conditionData)
    .then(res => {
      const data = res.data;
      dataSource.value = data.data;
      position.total = data.count;
      getLoading.value = false;
      loading.value = false;
      if (pagination) {
        position.current = pagination.current;
        position.pageSize = pagination.pageSize;
      } else {
        position.current = 1;
      }
    })
    .catch(error => {
      loading.value = false;
      getLoading.value = false;
      Modal.error({
        title: '请求出错,请稍后再试',
        content: error.response.data
      });
    });
};
let getLoading = ref(false);
//点击查询
const getListData = () => {
  conditionData = Object.assign(conditionData, search);
  conditionData.pageSize = position.pageSize;
  getInquire();
};
getListData();
//分页事件
const tableChange = pagination => {
  getInquire(pagination);
};
//下拉框--订单状态
const status = ref([]);
const getStatusData = () => {
  getOrderStatus()
    .then(res => {
      status.value = res.data;
    })
    .catch(err => {
      Modal.error({
        title: '订单状态请求出错,请刷新重试',
        content: err.response.data
      });
    });
};
getStatusData();

//选择客户简称获取套餐名称 因为不同客户有的套餐不同
const comboName = ref([]);
const selectClient = value => {
  getProducts(value)
    .then(res => {
      comboName.value = res.data;
    })
    .catch(err => {
      Modal.error({
        title: '获取套餐名称失败,请稍后重试',
        content: err.response.data
      });
    });
};
const reserveTime = computed(() => time => time.slice(0, 10));

//获取行详情
let visible = ref(false);
const rowDetail = ref({});
const getRowDetail = record => {
  return {
    onClick: () => {
      getLoading.value = true;
      loading.value = true;
      getOrderDetail(record.orderId)
        .then(res => {
          visible.value = true;
          loading.value = false;
          getLoading.value = false;
          rowDetail.value = res.data;
        })
        .catch(err => {
          getLoading.value = false;
          loading.value = false;
          Modal.error({
            title: '获取详情失败,请稍后重试',
            content: err.response.data
          });
        });
    }
  };
};
</script>

<style scoped>
.box {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.select-box {
  width: 200px;
  margin-right: 40px;
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt30 {
  margin-top: 30px;
}

.button-box {
  width: 200px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

:deep(.ant-table-tbody) .ant-table-cell {
  padding: 4px 16px !important;
}

:deep(.ant-descriptions) {
  padding: 0 20px;
}
</style>
